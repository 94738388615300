<template>
  <div>

    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="type=='view'?'再教育设置查看':type=='add'?'再教育设置新增':type=='edit'?'再教育设置编辑':'再教育设置'"
                 @head-save="headSave"
                 @head-save-back="headSave(true)"
                 @head-cancel="headCancel"></head-layout>
    <div class="newZtFormBox" style="background-color: white; padding-top:10px;padding-right: 10px;">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="120px">
        <el-row>

          <el-col :span="8">
            <el-form-item label='课程名称' prop="courseName">
              <el-input v-model="form.courseName" :disabled="readonly" placeholder='课程名称'
                        maxlength="255"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label='教育时间' prop="educationalTime">
              <el-date-picker
                v-model="form.educationalTime"
                type="datetimerange"
                range-separator="至"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :disabled="readonly"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="培训场景"
              prop="trainContentCode"
            >
              <avue-input-tree checkStrictly
                               default-expand-all
                               v-model="form.trainContentCode"
                               placeholder="请选择内容"
                               :props="defaultProps"
                               :disabled="readonly"
                               :dic="trainingScenariosTree"></avue-input-tree>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="培训通知"
              prop="trainingPlanId"
            >
              <el-select
                v-model="form.trainingNoticeId"
                placeholder="请选择培训计划"
                :popper-append-to-body="false"
                filterable
                :disabled="readonly"
                @change="trainingNotice"
              >
                <el-option
                  v-for="item in trainingPlans"
                  :key="item.id"
                  :label="item.trainTheme"
                  :value="item.trainingTaskId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label='合计课时'>
              <el-input-number v-model="form.totalHours" :precision="2" :step="0.1" :max="10000"
                               disabled></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label='达标课时' prop="meetLesson">
              <el-input-number v-model="form.meetLesson" :disabled="readonly" :precision="2" :step="0.1"
                               :max="10000"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label='课程简介' prop="remark">
              <el-input v-model="form.remark" :disabled="readonly" placeholder='说明' type="textarea"
                        maxlength="300" show-word-limit :rows="3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>


      </el-form>
    </div>
    <el-tabs type="border-card"
             v-model="classify" @tab-click="handClick">
      <el-tab-pane label="课程详情" name="courseDetails">
        <head-layout
          :head-btn-options="subTableheadBtnOptions"
          head-title="视频内容"
          @head-remove="headRemove"
          @head-add-tabs="subTableAdd"
          @head-cite="headCite"
        ></head-layout>
        <grid-layout
          ref="gridLayoutVide"
          class="serviceType"
          :grid-row-btn="gridRow"
          :table-options="subTableOption"
          :table-data="videdata"
          :table-loading="false"
          @gird-handle-select-click="selectionChange"
          @row-view="rowView"
          @row-remove="rowRemove"
          @row-add="rowAdd"
        >

        </grid-layout>
        <head-layout
          :head-btn-options="subTableheadBtnOptions"
          head-title="课件类型"
          @head-remove="headRemove"
          @head-add-tabs="subTableAddHeadCite"
          @head-cite="coursewareHeadCite"
        ></head-layout>
        <grid-layout
          ref="crud"
          class="serviceType"
          :grid-row-btn="gridRowBtn"
          :table-options="coursewareTableOption"
          :table-data="coursewareData"
          :table-loading="false"
          @gird-handle-select-click="selectionChange"
          @row-view="rowView"
          @row-remove="rowRemove"
        >

        </grid-layout>
        <head-layout
          :head-btn-options="testItemContent"
          head-title="试题内容"
          @head-remove="headRemove('testQuestions')"
          @head-cite="testQuestionsHeadCite"
        ></head-layout>
        <grid-layout
          ref="crud"
          class="serviceType"
          :grid-row-btn="gridRowBtn"
          :table-options="testQuestionssubTableOption"
          :table-data="testQuestionsData"
          :table-loading="false"
          @gird-handle-select-click="selectionChange"
          @row-view="rowViewTestQuestions"
          @row-remove="rowRemoveTestQuestions"
        >

        </grid-layout>
      </el-tab-pane>
      <el-tab-pane label="通知人员" name="deviceLocation">
        <notifyingOfficer ref="notifyingOfficer" :type="this.$route.query.type"
        ></notifyingOfficer>
      </el-tab-pane>
    </el-tabs>


    <el-dialog
      top="9vh"
      title="选择知识"
      class="paperDialog knowledge"
      :modal="true"
      :modal-append-to-body="false"
      :visible.sync="showKnowledgeDialog"
      width="50%"
      @close="closeKnowledgeDialog"
      v-if="showKnowledgeDialog"
    >
      <div style="display: flex">
        <!--        <tree-layout treeTitle="知识库" :tree-options="treeOptions" @tree-node-click="repositoryTreeNodeClick"-->
        <!--                     style="margin-right:5px;"></tree-layout>-->
        <CommonTree
          style="flex-shrink: 0"
          :treeData="treeData"
          :defaultProps="defaultProps"
          :searchTitle="searchTitle"
          :loading="treeLoading"
          :showCheckbox="false"
          treeTitle="知识库"
          @getNodeClick="repositoryTreeNodeClick"
        />
        <div style="width: calc(100% - 280px);">
          <grid-layout
            ref="gridKnowledgeLayOut"
            :table-options="tableKnowledgeOptions"
            :table-data="tableKnowledgeData"
            :table-loading="tableKnowledgeLoading"
            :data-total="pageKnowledge.total"
            :page="pageKnowledge"
            :selectable="selectable"
            @page-current-change="onLoadKnowledge"
            @page-size-change="onLoadKnowledge"
            @page-refresh-change="onLoadKnowledge"
            @gird-handle-select-click="selectionKnowledgeChange"
          ></grid-layout>
        </div>
      </div>
      <span slot="footer" class="dialogBtn">
        <el-button size="medium" type="primary" @click="confirmKnowledge">确认</el-button>
        <el-button size="medium" @click="closeKnowledgeDialog">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="选择试卷"
      :modal-append-to-body="false"
      :visible.sync="showExaminationDialog"
      width="80%"
      class="paperDialog"
    >
      <el-container v-if="showExaminationDialog">
        <CommonTree
          :titleShow="false"
          :treeData="examinationTreeData"
          :defaultProps="examinationDefaultProps"
          :searchTitle="searchTitle"
          :showCheckbox="false"
          @getNodeClick="treeNodeClick"
        />
        <el-main>
          <grid-head-layout
            ref="gridHeadLayout"
            :search-columns="searchColumns"
            @grid-head-search="gridHeadSearch"
            @grid-head-empty="gridHeadEmpty"
          ></grid-head-layout>
          <grid-layout
            ref="gridLayOut"
            :table-options="tableOptions"
            :table-data="tableData"
            :table-loading="tableLoading"
            :data-total="paperPage.total"
            :page="paperPage"
            :selectable="selectable"
            @page-current-change="onLoad"
            @page-size-change="onLoad"
            @page-refresh-change="onLoad"
            @gird-handle-select-click="selectionChange"
          ></grid-layout>
        </el-main>
      </el-container>
      <span slot="footer" class="dialogBtn">
        <el-button size="medium" type="primary" @click="confirm">确认</el-button>
        <el-button size="medium" @click="closeDialog">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      title="上传"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showVideo"
      width="35%"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="120px">
        <el-row>
          <el-col :span="8" style="margin-right:179px;">
            <el-form-item label='课程名称' prop="courseName">
              <el-input v-model="formVideo.fileName" placeholder='课程名称' maxlength="50"
                        style="width: 240px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <div
            >
              <el-upload
                class="upload-demo"
                action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeUpload"
                ref="uploadRef"
                :headers="headers"
              >
                <el-button size="small" type="primary" icon="el-icon-upload" :disabled="videoButton"
                >点击上传
                </el-button
                >
              </el-upload>
            </div>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer">
        <el-button size="small" @click="videoSubmission">提交</el-button>
      </span>
      <span slot="footer">
        <el-button size="small" @click="showVideo = false">取消</el-button>
      </span>
    </el-dialog>
    <video ref="video" id="myVideo" :src="videoUrl" crossOrigin="anonymous" style="display: none"></video>
    <canvas id="myCanvas" style="display: none" crossOrigin="anonymous"></canvas>
  </div>

</template>

<script>

import FormLayout from "@/views/components/layout/form-layout";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import CommonTree from "@/views/components/com_tree";
import { putFile} from "@/api/system/user";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {mapGetters} from "vuex";
import {
  categoryHeadTree, onTheJobTraininglist, trainingTaskSelect
} from "@/api/onTheJobTraining/training";
import {getList} from "@/api/training/edupaper";
import {getEduTree} from "@/api/training/edutree";
import website from "@/config/website";
import {getToken} from "@/util/auth";
import TreeLayout from "@/views/components/layout/tree-layout";
import notifyingOfficer from "@/views/business/training/reEducationSetting/components/notifyingOfficer";
import {
  getTrainingRducationSettingTreeTree,
  trainingEducationSetHeadDetail,
  trainingEducationSetHeadSubmit, trainingEducationSetLineRemove,
  trainingEducationSetLineRetentionTime, trainingEducationSetLineSubmit, trainingTaskDetail
} from "@/api/training/reEducationSetting";

export default {
  name: "dataCoonfigAdd",
  mixins: [exForm, draft],
  computed: {
    ...mapGetters(['userInfo']),
    headers: function () {
      return {"Sinoma-Auth": getToken()};
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.$route.query.type != 'view') {
        buttonBtn.push(
          {
            label: this.$t("cip.cmn.btn.saveBtn"),
            emit: "head-save",
            type: "button",
            icon: "",
          }
        )
        buttonBtn.push({
          label: this.$t("cip.cmn.btn.saveBackBtn"),
          emit: "head-save-back",
          type: "button",
          icon: "",
        })
      }
      buttonBtn.push({
        label: this.$t("cip.cmn.btn.celBtn"),
        emit: "head-cancel",
        type: "button",
        icon: "",
      })
      return buttonBtn;
    },
    subTableheadBtnOptions() {
      let buttonBtn = [];
      if (this.$route.query.type != 'view') {
        buttonBtn.push({
          label: "新增",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
        buttonBtn.push({
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        });
        buttonBtn.push({
          label: "引用",
          emit: "head-cite",
          type: "button",
          icon: "",
          btnOptType: "cite",
        });
      }

      return buttonBtn
    },
    testItemContent() {
      let buttonBtn = [];
      if (this.$route.query.type != 'view') {
        buttonBtn.push({
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        });
        buttonBtn.push({
          label: "引用",
          emit: "head-cite",
          type: "button",
          icon: "",
          btnOptType: "cite",
        });
      }
      return buttonBtn
    },
    gridRow() {
      let buttonBtn = [];

      if (this.$route.query.type == 'edit' || this.$route.query.type == 'add' || this.$route.query.type == 'notice') {
        buttonBtn.push({
          label: "删除",
          emit: "row-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        });
        buttonBtn.push({
          label: "保存",
          emit: "row-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
      }
      if (this.$route.query.type == 'view') {
        buttonBtn.push({
          label: "查看",
          emit: "row-view",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn
    },
    gridRowBtn() {
      let buttonBtn = [];
      if (this.$route.query.type == 'edit' || this.$route.query.type == 'add' || this.$route.query.type == 'notice') {
        buttonBtn.push({
          label: "删除",
          emit: "row-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        });

      }
      if (this.$route.query.type == 'view') {
        buttonBtn.push({
          label: "查看",
          emit: "row-view",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn
    },
    searchColumns() {
      return [
        {
          prop: "code",
          placeholder: this.$t('cip.cmn.rule.inputWarning') + "试卷编号",
          span: 4,

        },
        {
          prop: "paperName",
          placeholder: this.$t('cip.cmn.rule.inputWarning') + "试卷名称",
          span: 4,
        },
        // {
        //   placeholder: this.$t('cip.cmn.rule.selectWarning') + "考试级别",
        //   prop: "lexaminationLevel",
        //   type: 'select',
        //   span: 4,
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_EXAMINATION_LEVEL',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        // },
      ]
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        menu: false,
        selectable: this.selectable,
        column: [
          {
            label: "试卷编号",
            prop: "code",
            width: 180,
            align: 'left',
            overHidden: true,
          },
          {
            label: "试卷名称",
            prop: "paperName",
            align: 'left',
            overHidden: true,
          },
          // {
          //   label: "考试级别",
          //   prop: "lexaminationLevel",
          //   align: "center",
          //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_EXAMINATION_LEVEL',
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          //   width: 110,
          //   overHidden: true,
          // },
          // {
          //   label: "出题依据",
          //   prop: "accordingTo",
          //   align: "center",
          //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_ACCORDING_TO',
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          //   width: 150,
          //   overHidden: true,
          // },
          {
            label: "专业分类",
            prop: "subjectType",
            dicData: [],
            type: "tree",
            props: {
              label: "title",
              value: "value",
            },
            dataType: 'string',
            align: 'center',
            width: 200,
            overHidden: true,
          },

          {
            label: "试卷状态",
            prop: "paperStatus",
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=paper_status',
            dataType: 'number',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            width: 110,
            align: 'center',
            overHidden: true,
          },

        ]
      }
    },
    //知识库
    tableKnowledgeOptions() {
      return {
        index: true,
        indexLabel: "序号",
        menu: false,
        selectable: (row) => {
          if (row.attachList[0]) {
            return this.selectedKnowledgeIds.indexOf(row.attachList[0].id) === -1
          }
        },
        column: [
          {
            label: "名称",
            align: "left",
            prop: "fileName",
            overHidden: true,
          },
          {
            label: "格式",
            align: "center",
            prop: "extension",
            width: 120,
          },
          {
            label: "上传人",
            align: "center",
            prop: "createName",
            width: 80,
            dicData: []
          },
          {
            label: "时长",
            align: "center",
            prop: "duration",
            width: 80,
          },
          {
            label: "上传时间",
            align: "center",
            prop: "updateTime",
            width: 220,
          },
          {
            label: "状态",
            align: "center",
            prop: "status",
            width: 120,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_training_plan_status",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: "number",
          },
        ],
      };
    },
    testQuestionssubTableOption() {
      // customAddBtn: true,
      // addBtn: false,
      // editBtn: false,
      // rowKey: 'id',
      // rowParentKey: 'parentId',
      return {
        menuWidth: 120,
        column: [
          {
            label: "试题名称",
            prop: 'paperName',
            cell: true,
          },
          {
            label: "专业分类",
            prop: "subjectType",
            dicData: [],
            type: "tree",
            props: {
              label: "title",
              value: "value",
            },
            dataType: 'string',
            align: 'center',
            overHidden: true,
          },
        ]
      }

    },
  },
  data() {
    return {
      classify: "courseDetails",
      treeOptions: {
        defaultExpandAll: false,
        nodeKey: 'id',
        lazy: true,
        treeLoad: function (node, resolve) {
          const parentId = (node.level === 0) ? 0 : node.data.id;
          console.log("nodenode", parentId)
          categoryHeadTree(parentId).then(res => {
            resolve(res.data.data.map(item => {
              return {
                ...item,
                leaf: !item.hasChildren
              }
            }))
          });
        },
        menu: false,
        addBtn: false,
        // size: 'mini',
        props: {
          labelText: this.$t("cip.plat.sys.user.field.title"),
          label: 'title',
          value: 'value',
          children: 'children'
        }
      },
      readonly: false,
      repositoryTreeData: [],
      treeData: [],
      treeLoading: false,
      videoUrl: "",
      thumbnail: "",
      videoButton: false,
      formVideo: {},
      showVideo: false,
      fileUrl: "",
      selectionList: [],
      searchTitle: "title",
      tableData: [],
      userRelationList: [],
      trainingScenariosTree: [],
      trainingPlans: [], //培训计划
      defaultProps: {
        label: "title",
        value: "value",
        key: "key",
      },
      examinationTreeData: [],
      tableKnowledgeData: [],
      tableKnowledgeLoading: false,
      selectedKnowledgeList: [],
      readonlyL: false,
      testQuestionsData: [],
      paperIds: [],
      selectedKnowledgeIds: [],
      showKnowledgeDialog: false,
      form: {
        trainingScenarios: "",
        totalHours: "0",
        meetLesson: "0",
        trainingNoticeId: "",

        educationalTime: [],
      },
      type: "",
      data: [],
      processInstanceId: '',
      dialogType: "",
      videdata: [],
      coursewareData: [],
      tableLoading: false,
      showFileDialog: false,
      pageKnowledge: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      onLoadKnowledgeForm: {
        fileTypeId: "",
        knowledgeType: "",
      },
      repositoryDefaultProps: {
        label: "title",
        value: "value",
        key: "key"
      },
      examinationDefaultProps: {
        label: "title",
        value: "id",
        key: "id"
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      showExaminationDialog: false,
      paperPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      subTableOption: {
        addBtn: false,
        rowKey: 'id',
        editBtn: true,
        cellBtn: false,
        menuWidth: 120,
        column: [
          {
            label: "课程名称",
            prop: 'fileName',
          },
          {
            label: "时长",
            prop: 'fileTag',
          },
          {
            label: "课时",
            prop: 'classHour',
            span: 8,
            type: 'number',
            cell: true,
            minRows: 0,
            maxRows: 1000000000,
            disabled: !['edit', 'add', 'notice'].includes(this.$route.query.type),
            change: (row) => {
              let number = 0;
              this.videdata.forEach((item) => {
                number += Number(item.classHour)
              })
              this.form.totalHours = number
            },
          },
          {
            label: '是否必修',
            prop: 'status',
            labelWidth: 80,
            type: 'switch',
            align: "center",
            props: {
              label: "label",
              value: "value"
            },
            disabled: !['edit', 'add', 'notice'].includes(this.$route.query.type),
            dataType: 'string',
            overHidden: true,
            cell: true,
            dicData: [
              {
                label: '否',
                value: "0"
              },
              {
                label: '是',
                value: "1",
              },
            ],
          },
        ]
      },
      coursewareTableOption: {
        customAddBtn: true,
        addBtn: false,
        editBtn: false,
        rowKey: 'id',
        rowParentKey: 'parentId',
        menuWidth: 120,
        column: [
          {
            label: "课件名称",
            prop: 'fileName',
            cell: true,
          },
          {
            label: "类型",
            prop: 'extension',
            cell: true,
          },

        ]
      },

      rules: {

        meetLesson: [
          {
            required: true,
            message: '请设置达标课时',
            trigger: "blur",
          }
        ],
        educationalTime: [
          {
            required: true,
            message: '请选择教育时间',
            trigger: "blur",
          }
        ],
        courseName: [
          {
            required: true,
            message: '请输入课程名称',
            trigger: "blur",
          }
        ],
        trainingScenarios: [
          {
            required: true,
            message: '请选择培训类型',
            trigger: "blur",
          }
        ],
      },
    };
  },
  components: {
    FormLayout,
    GridLayout,
    HeadLayout,
    CommonTree,
    TreeLayout,
    notifyingOfficer
  },
  created() {

  },
  mounted() {
    trainingTaskSelect(1).then((res) => {
      const data = res.data.data;
      this.trainingPlans = data;
    });
    getTrainingRducationSettingTreeTree()
      .then((result) => {
        this.trainingScenariosTree = result.data.data;
      })
    //从任务下发进来的数据
    if (this.$route.query.type == 'notice') {
      var parse = JSON.parse(this.$route.query.row);
      this.form.trainingNoticeId = parse.id
      this.form.trainingNotice = parse.trainTheme
      this.userRelationList = parse.userRelationList;
    }
    if (this.$route.query.type == 'add') {
      this.form.trainContentCode = this.$route.query.trainContentId
      this.form.trainingScenarios = this.$route.query.trainContentName
    }
    this.type = this.$route.query.type
    if (this.$route.query.type == 'view') {
      this.readonly = true
    }
    if (this.$route.query.type == 'edit' || this.$route.query.type == 'view') {
      let row = JSON.parse(this.$route.query.row);
      this.initDate(row.id)
    }

  },

  methods: {
    trainingNotice(row) {
      trainingTaskDetail({id: this.form.trainingNoticeId}).then(res => {
        this.userRelationList = res.data.data.userRelationList;
        this.$refs.notifyingOfficer.trainingNotificationPersonnel(this.userRelationList, this.form.id)
      })
    },
    selectable(row) {
      return this.paperIds.indexOf(row.id) === -1
    },
    handClick() {
      if (!this.form.id) {
        this.$message.error("请先保存头表信息");
      }
      if (this.classify == 'deviceLocation') {
        console.log(this.$refs.notifyingOfficer, '857')
        this.$refs.notifyingOfficer.onLoadExamUser({
          pageSize: 20,
          currentPage: 1
        }, {educationId: this.form.id}, this.userRelationList, this.form.id)
      }
    },
    repositoryTreeNodeClick(node) {
      console.log("进行输出---------", node)
      this.treeDeptId = node.id;
      this.pageKnowledge.currentPage = 1;
      this.onLoadKnowledgeForm.knowledgeType = node.id
      this.onLoadKnowledgeForm.fileTypeId = node.id
      this.onLoadKnowledge(this.pageKnowledge)

    },
    videoSubmission() {
      if (this.formVideo.fileName) {
        if (this.$refs.uploadRef.uploadFiles.length > 0) {
          trainingEducationSetLineSubmit(this.formVideo).then(res => {
            this.initDate(this.form.id)
            this.$message({
              type: "success",
              message: "保存成功！",
            });
            this.$refs.uploadRef.uploadFiles = []
            this.showVideo = false;
          })
        } else {
          this.$message.warning("请上传视频或课件文件后再进行提交！")
        }
      } else {
        this.$message.warning("请确保课程名称不为空再进行提交！")
      }

    },
    handleAvatarSuccess(res, file, fileList) {


      this.formVideo.educationContentId = file.response.data.attachId
      this.formVideo.educationId = this.form.id


      this.$message({
        type: "success",
        message: "上传成功！",
      });
      this.videoButton = true
    },
    treeNodeClick(node) {
      let params = {
        subjectType: node.id
      }
      this.paperPage.currentPage = 1
      this.onLoad(this.paperPage, params)
    },
    dataFromat(list) {

      let a = []
      list.forEach(e => {
        a.push(this.fun2(e))
      })
      Promise.all(a).then(res => {
        trainingEducationSetLineRetentionTime({trainingEducationSetLines: res})
      })
    },
    formatTime(seconds) {
      var hours = Math.floor(seconds / 3600);
      var minutes = Math.floor((seconds % 3600) / 60);
      var remainingSeconds = seconds % 60;

      // 将时、分、秒转换为两位数的格式
      var hoursStr = this.padZero(hours);
      var minutesStr = this.padZero(minutes);
      var secondsStr = this.padZero(remainingSeconds);

      return hoursStr + ":" + minutesStr + ":" + secondsStr;
    },
    padZero(num) {
      return (num < 10 ? "0" : "") + num;
    },
    fun2(e) {
      return new Promise(resolve => {
        let that = this;
        var video = document.createElement('video');
        video.src = e.fileCover;
        video.addEventListener('loadedmetadata', function () {
          var seconds = video.duration;
          e.fileTag = that.formatTime(Math.floor(seconds));
          let cc = {
            educationId: that.form.id,
            educationContentId: e.id,
            duration: e.fileTag
          }
          resolve(cc)
        })
      })

    },

    async initDate(id) {
      trainingEducationSetHeadDetail(id).then(res => {
        this.initTree();
        this.form = res.data.data
        if (res.data.data.trainingNoticeId == -1) {
          this.form.trainingNoticeId = null
        }
        if (res.data.data.kmInfoVideoList.length > 0) {
          this.dataFromat(res.data.data.kmInfoVideoList)
          let number = 0;
          res.data.data.kmInfoVideoList.forEach((item) => {
            number += Number(item.classHour)
          })
          this.form.totalHours = number
        } else {
          this.form.totalHours = 0
        }
        this.videdata = res.data.data.kmInfoVideoList
        this.videdata.forEach((item, index) => {
          this.$refs.gridLayoutVide.getGrid().rowCell(item, index);

        })
        this.coursewareData = res.data.data.kmInfoCoursewareList
        this.testQuestionsData = res.data.data.eduPaperList
        this.$refs.notifyingOfficer.reception(this.form.id);
      })
    },
    base64ToFile(base64Data, filename) {
      // 将base64的数据部分提取出来
      const parts = base64Data.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);

      // 将原始数据转换为Uint8Array
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      // 使用Blob和提取出的数据内容创建一个新的Blob对象
      const blob = new Blob([uInt8Array], {type: contentType});

      // 创建一个指向Blob对象的URL，并使用这个URL创建一个File对象
      const blobUrl = URL.createObjectURL(blob);
      const file = new File([blob], filename, {type: contentType});

      // 返回File对象
      return file;
    },
    beforeUpload(file) {
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf('.'));
      if (this.dialogType == "courseware") {
        this.formVideo.type = 2
        if (fileType !== '.jpg' && fileType !== '.jpeg' && fileType !== '.png' && fileType !== '.pdf' && fileType !== '.excel' && fileType !== '.word' && fileType !== '.doc' && fileType !== '.docx' && fileType !== '.xls' && fileType !== '.xlsx' && fileType !== '.txt') {
          this.$message({
            type: "warning",
            message: "请上传jpg、jpeg、png、pdf、excel、word、doc、docx、xls、xlsx、txt类型文件！",
          });
          return false;
        }
      } else if (this.dialogType == "video") {
        this.formVideo.type = 1
        if (fileType !== '.mp4' &&
          fileType !== '.avi' &&
          fileType !== '.mkv' &&
          fileType !== '.mov' &&
          fileType !== '.wmv' &&
          fileType !== '.flv' &&
          fileType !== '.mpeg') {
          this.$message({
            type: "warning",
            message: "请上传mp4、avi、mkv、mov、wmv、flv、mpeg类型文件！",
          });
          return false;
        }
      }
      // const isLt20M = file.size / 1024 / 1024 < 20;
      // if (!isLt20M) {
      //   this.$message.warning('上传文件大小不能超过 20MB!');
      // }
      // return isLt20M;
      return true;
    },
    coursewareForm() {
      this.form.eduPaperList = []
      this.form.kmInfoVideoList = []
      console.log("this.selectionKnowledgeList", this.selectionKnowledgeList)
      let date = [];
      this.selectionKnowledgeList.forEach(item => {
        let aa = {
          fileName: item.fileName,
          educationContentId: item.attachList[0].id,
        }
        date.push(aa);
      })
      this.form.kmInfoCoursewareList = date
      this.trainGroupScene();
    },
    trainGroupScene() {
      trainingEducationSetHeadSubmit(this.form).then(res => {
        this.initDate(res.data.data.id)
        this.showKnowledgeDialog = false;
        return this.$message({
          type: "success",
          message: "操作成功！",
        });
      })
    },
    gridHeadEmpty(query) {
      this.onLoad(this.paperPage, query)
    },
    confirm() {

      if (this.selectionList.length === 0) {
        this.$message.warning("请选择试卷");
        return;
      }
      this.form.kmInfoVideoList = []
      this.form.kmInfoCoursewareList = []
      this.form.eduPaperList = this.selectionList;
      this.trainGroupScene();
      this.closeDialog();
    },
    videoForm() {
      this.form.eduPaperList = []
      this.form.kmInfoCoursewareList = []
      let date = [];
      this.selectionKnowledgeList.forEach(item => {
        let aa = {
          fileName: item.fileName,
          educationContentId: item.attachList[0].id,
        }
        date.push(aa);
      })
      this.form.kmInfoVideoList = date
      this.trainGroupScene();
    },
    confirmKnowledge() {

      if (this.dialogType == "courseware") {
        this.coursewareForm();
      } else if (this.dialogType == "video") {
        this.videoForm();
      }
    },
    selectionKnowledgeChange(list) {
      this.selectionKnowledgeList = list
    },
    //知识库
    closeKnowledgeDialog() {
      this.pageKnowledge.currentPage = 1;
      this.showKnowledgeDialog = false;
    },
    closeDialog() {
      this.selectionList = [];
      this.paperPage.currentPage = 1;
      this.showExaminationDialog = false;
    },
    initTree() {
      getEduTree()
        .then(res => {
          let {data} = res.data
          this.examinationTreeData = data;
          console.log("进行输出--examinationTreeData--", this.examinationTreeData)
          let testQuestionColumn = this.findObject(this.testQuestionssubTableOption.column, 'subjectType');
          testQuestionColumn.dicData = data;
        })
    },
    // initKnowledgeClassificationTree() {
    //   categoryHeadTree({knowledgeClassifyId:""})
    //     .then(res => {
    //       let {data} = res.data
    //       this.repositoryTreeData = data;
    //       console.log("this.repositoryTreeData",this.repositoryTreeData)
    //     })
    // },
    testQuestionsHeadCite() {
      if (!this.form.id) {
        return this.$message.error("请先保存头表信息");
      }
      this.paperIds = [];
      this.showExaminationDialog = true;
      this.initTree();
      let column = this.findObject(this.tableOptions.column, 'subjectType');
      column.dicData = this.examinationTreeData;
      console.log("123，", this.form.eduPaperList)
      this.form.eduPaperList.forEach((item) => {
        this.paperIds.push(item.id)
      })
      this.onLoad(this.paperPage);
    },
    rowView(item) {
      console.log("item", item)
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL + "?url=" +
        encodeURIComponent(Base64.encode(item.fileCover));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    onLoad(page, params = {}) {
      this.paperPage = page;
      this.tableLoading = true;
      //只获取试卷类型为已发布的
      params.paperStatus = 1;
      getList(page.currentPage, page.pageSize, Object.assign(params)).then(res => {
        const data = res.data.data;
        this.tableData = data.records;
        this.tableLoading = false;
        this.$nextTick(() => {
          this.$refs.gridLayOut.page.total = data.total;
          this.$refs.gridLayOut.selectionClear();
        })
      });
    },
    coursewareHeadCite() {
      if (!this.form.id) {
        return this.$message.error("请先保存头表信息");
      }
      this.showKnowledgeDialog = true;
      this.selectionKnowledgeList = [];
      this.selectedKnowledgeIds = [];
      this.onLoadKnowledgeForm = []
      this.form.kmInfoCoursewareList.forEach((item) => {
        this.selectedKnowledgeIds.push(item.educationContentId)
      })
      this.dialogType = "courseware"
      categoryHeadTree("000000")
        .then((result) => {
          this.treeData = result.data.data;
          this.node = this.treeData[0];
          this.treeLoading = false;
          this.onLoadKnowledge(this.pageKnowledge);
        }).catch((err) => {
        this.treeLoading = true;
      });
    },
    gridHeadSearch(query) {
      this.paperPage.currentPage = 1;
      this.onLoad(this.paperPage, query)
    },
    headCite() {
      if (!this.form.id) {
        return this.$message.error("请先保存头表信息");
      }
      this.showKnowledgeDialog = true;
      this.selectionKnowledgeList = [];
      this.onLoadKnowledgeForm = []
      this.selectedKnowledgeIds = [];
      this.form.kmInfoVideoList.forEach((item) => {
        this.selectedKnowledgeIds.push(item.educationContentId)
      })
      this.dialogType = "video"
      categoryHeadTree("000000")
        .then((result) => {
          this.treeData = result.data.data;
          this.node = this.treeData[0];
          this.treeLoading = false;
          this.onLoadKnowledge(this.pageKnowledge);
        }).catch((err) => {
        this.treeLoading = true;
      });
    },
    onLoadKnowledge(page, knowledgeType, fileTypeId) {
      this.pageKnowledge = page;
      this.tableKnowledgeLoading = true;
      onTheJobTraininglist(
        page.currentPage,
        page.pageSize,
        {
          documentType: this.dialogType,
          knowledgeType: this.onLoadKnowledgeForm.knowledgeType,
          fileTypeId: this.onLoadKnowledgeForm.fileTypeId
        }
      ).then((res) => {
        const data = res.data.data;
        if (this.isEmptyObject(data)) {
          this.$refs.gridKnowledgeLayOut.page.total = 0;
          this.pageKnowledge.total = 0;
        } else {
          this.$refs.gridKnowledgeLayOut.page.total = data.total;
          this.pageKnowledge.total = data.total;
        }
        this.tableKnowledgeData = data.list;
        this.tableKnowledgeLoading = false;
      });
    },
    isEmptyObject(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    subTableAdd() {
      if (!this.form.id) {
        return this.$message.error("请先保存头表信息");
      }
      this.showVideo = true;
      this.formVideo = {}
      this.videoButton = false
      this.dialogType = "video"
      // this.$router.push({
      //   path: `/knowledge/knowledgeUpload`,
      //   query: {
      //     type: "add",
      //   },
      // });
    },
    subTableAddHeadCite() {
      if (!this.form.id) {
        return this.$message.error("请先保存头表信息");
      }
      this.showVideo = true;
      this.formVideo = {}
      this.videoButton = false
      this.dialogType = "courseware"
    },
    rowViewTestQuestions(row) {
      this.$router.push({
        path: '/training/paper/edupaperView',
        query: {
          row: JSON.stringify(row),
          type: 'view',
        }
      });
    },
    rowAdd(row) {
      console.log(row)
      let listVido = []
      listVido.push(row);
      let cc = {
        id: this.form.id,
        totalHours: this.form.totalHours,
        kmInfoVideoList: listVido
      }
      trainingEducationSetHeadSubmit(cc).then(res => {
        this.initDate(res.data.data.id)
        return this.$message({
          type: "success",
          message: "操作成功！",
        });
      })
    },
    /**
     * 试题删除
     * @param row
     */
    rowRemoveTestQuestions(row) {
      trainingEducationSetLineRemove(this.form.id, row.id).then(res => {
        this.initDate(this.form.id)
        return this.$message({
          type: "success",
          message: "删除成功！",
        });
      })
    },
    rowRemove(row, type) {
      this.$confirm("确定将数据删除？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          trainingEducationSetLineRemove(this.form.id, row.educationContentId).then(res => {
            this.initDate(this.form.id)
            return this.$message({
              type: "success",
              message: "删除成功！",
            });
          })
        })
    },
    headRemove(type) {
      if (this.selectionList.length == 0) {
        this.$message.warning("请选择所要删除的数据！")
        return false
      }
      var ids = ""
      if (type == "testQuestions") {
        ids = this.selectionList.map(item => item.id);
      } else {
        ids = this.selectionList.map(item => item.educationContentId);
      }
      this.$confirm("确定删除所选择的数据？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log("this.selectionListthis.selectionList", this.selectionList)
          trainingEducationSetLineRemove(this.form.id, ids.join(",")).then(res => {
            this.initDate(this.form.id)
            this.selectionList = []
            return this.$message({
              type: "success",
              message: "删除成功！",
            });
          })
        })
    },

    selectionChange(list) {
      this.selectionList = list;
    },
    headSave(cancel = false) {
      if ((this.$route.query.type == 'add' || this.$route.query.type == 'edit' || this.$route.query.type == 'notice') && cancel) {
        var cc = true;
        if (this.videdata.length > 0 || this.coursewareData.length > 0 || this.testQuestionsData.length > 0) {
          cc = false
        }
        if (cc) {
          return this.$message({
            type: "error",
            message: "请点击保存按钮在视频内容、课件类型、试题内容中至少保存一条数据再进行返回！",
          });
        }
      }
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          //集团培训
          //如果没有视频使用默认图片
          let that = this;
          var educationalTime = that.form.educationalTime;
          that.form.startDate = that.form.educationalTime[0]
          that.form.endDate = that.form.educationalTime[1]
          that.form.trainingEducationSetDetils = this.$refs.notifyingOfficer.information();
          that.$loading();
          if (that.form.kmInfoVideoList && that.form.kmInfoVideoList.length > 0) {
            that.videoUrl = that.form.kmInfoVideoList[0].fileCover;
            that.$nextTick(() => {
              const video = document.getElementById('myVideo');
              const canvas = document.getElementById('myCanvas');
              const ctx = canvas.getContext('2d');
              video.addEventListener('loadeddata', function () {
                video.currentTime = 3;
                setTimeout(() => {
                  canvas.width = video.videoWidth;
                  canvas.height = video.videoHeight;
                  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                  const imageURL = canvas.toDataURL('image/png');
                  const formData = new FormData();
                  formData.append('file', that.base64ToFile(imageURL, 'image.png'));
                  putFile(formData).then((res) => {
                    //集团培训
                    that.form.type = 2
                    that.form.courseCover = res.data.data.link
                    //存路径所以不需要更新
                    that.form.methodOfPreservation = "head"
                    trainingEducationSetHeadSubmit(that.form).then(res => {
                      that.form.methodOfPreservation = "table"
                      that.initDate(res.data.data.id)
                      // this.$refs.notifyingOfficer.trainingNotificationPersonnel(this.userRelationList,res.data.data.id)
                      that.$message({
                        type: "success",
                        message: "保存成功！",
                      });
                      that.$loading().close();
                      if (cancel) {
                        that.headCancel()
                      }
                    })
                  })
                }, 3000)
              });
            });
          } else {
            that.form.type = 2
            that.form.methodOfPreservation = "head"

            trainingEducationSetHeadSubmit(that.form).then(res => {
              that.form.methodOfPreservation = "table"
              that.initDate(res.data.data.id)
              // this.$refs.notifyingOfficer.trainingNotificationPersonnel(this.userRelationList,res.data.data.id)
              that.$message({
                type: "success",
                message: "保存成功！",
              });
              that.$loading().close();
              if (cancel) {
                that.headCancel()
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    headCancel() {

      if (this.form.id) {
        if (this.$route.query.type == 'add' || this.$route.query.type == 'notice') {
          var cc = true;
          if (this.videdata.length > 0 || this.coursewareData.length > 0 || this.testQuestionsData.length > 0) {
            cc = false
          }
          if (cc) {
            return this.$message({
              type: "error",
              message: "请点击保存按钮在视频内容、课件类型、试题内容中至少保存一条数据再进行返回！",
            });
          }
        }
      }
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

  },
};
</script>

<style lang="scss" scoped>
::v-deep .avue-crud .el-table {
  height: calc(100vh - 423px) !important;
  max-height: calc(100vh - 423px) !important;
}

.knowledgeTree .common_tree_handle .el-tree {
  height: calc(100vh - 500px) !important;
}

::v-deep .avue-tree {
  height: calc(100vh - 417px);
}

::v-deep .serviceType .avue-crud .el-table {
  height: calc(100vh - 600px) !important;
  max-height: calc(100vh - 600px) !important;
}

::v-deep .common_tree_handle .el-tree {
  /* height: 100% !important; */
  height: calc(100vh - 459px) !important;
  overflow-y: scroll;
}

//
//::v-deep .common_tree_handle .el-tree {
//  height: calc(100vh - 122px) !important;
//}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: { "head-title": "通知人员", "head-btn-options": _vm.headBtn },
        on: {
          "head-add-tabs": _vm.headAddTabs,
          "head-remove-tabs": _vm.headRemoveTabs,
        },
      }),
      _c("grid-head-layout", {
        ref: "searchForm",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.searchChange,
          "grid-head-empty": _vm.searchReset,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
        },
        on: {
          "page-refresh-change": _vm.onLoadExamUser,
          "gird-handle-select-click": _vm.selectionChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("cip.plat.sys.user.field.roleConfig"),
            "append-to-body": "",
            visible: _vm.roleBox,
            width: "90%",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.roleBox = $event
            },
          },
        },
        [
          _vm.roleBox
            ? _c("departmentPersonnel", {
                ref: "departmentPersonnel",
                attrs: { "disabled-list": _vm.disabledErpItemList },
              })
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.roleBox = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.submitBox()
                    },
                  },
                },
                [_vm._v("\n            确定\n          ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }